<template>
  <div>
    <b-avatar :src="logo" class="mx-1" size="38" />
    <b-dropdown
      style="margin-left: -13px"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :text="name_user"
      variant="flat-primary"
    >
      <b-dropdown-item
        v-for="store in data_stores"
        :key="store.id"
        @click="
          setstore(
            store.pictrue,
            store.id,
            store.store_name,
            store.business_type,
            store.package,
            store.user_id,
            store.line_oa
          )
        "
      >
        <b-avatar
          :src="store.line_oa ? store.line_oa.logo : null"
          class="mx-1"
          size="20"
        />
        {{ store.store_name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";

export default {
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name_user: "",
      data_stores: "",
      show: false,
      logo: localStorage.getItem("logo"),
    };
  },
  mounted() {
    // setInterval(()=>{

    //   this.$store.state.count++
    // },1000)
    this.loaddata();
    this.name_user = localStorage.getItem("store_name");
  },
  methods: {
    async loaddata() {
      var userData = JSON.parse(localStorage.getItem("userData"));
      //  console.log('userData :>> ', userData);
      const params = {
        user_main: userData.user_main,
      };
      api
        .post("get_store_switch", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("get_store_switch response.data :>> ", response.data);
          this.data = response.data;
          this.data_stores = response.data.users;
        })
        .catch((error) => {});
    },
    setstore(
      pictrue,
      id,
      store_name,
      business_type,
      package_stroe,
      user_id,
      lineoa
    ) {
      console.log("id :>> ", id);
      console.log("pictrue :>> ", pictrue);
      console.log("store_name :>> ", store_name);
      console.log("business_type :>> ", business_type);
      console.log("package_stroe :>> ", package_stroe);
      console.log("user_id :>> ", user_id);
      localStorage.setItem("store_id", id);
      localStorage.setItem("store_name", store_name);
      localStorage.setItem("logo", lineoa ? lineoa.logo : null);
      // this.$router.push({ name: "store-detail" });
      if (!lineoa) {
        localStorage.setItem("logo", null);
        this.$router.push("/lineoa/add");
      } else {
        if (this.$route.name === "connectOA") {
          this.$router.push({ name: "store-detail" });
        }
        this.show = true;
        this.$forceUpdate()
        setInterval(() => {
          window.location.reload();
          // this.$router.go();
        }, 1000);
      }
    },
  },
};
</script>
