export default [
    // {
    //     title: 'Back',
    //     route: 'campaign-back',
    //     icon: 'ChevronLeftIcon',
    // },
    // {
    //     header: 'Campain',
    // },

    // {
    //     title: 'Campain',
    //     route: 'campaign',
    //     icon: 'GiftIcon',
    // },
    // {
    //     title: 'Add Campain',
    //     route: 'campaign-add',
    //     icon: 'PlusIcon',
    // },
   

]
