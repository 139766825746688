var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":_vm.logo,"size":"38"}}),_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticStyle:{"margin-left":"-13px"},attrs:{"text":_vm.name_user,"variant":"flat-primary"}},_vm._l((_vm.data_stores),function(store){return _c('b-dropdown-item',{key:store.id,on:{"click":function($event){return _vm.setstore(
          store.pictrue,
          store.id,
          store.store_name,
          store.business_type,
          store.package,
          store.user_id,
          store.line_oa
        )}}},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":store.line_oa ? store.line_oa.logo : null,"size":"20"}}),_vm._v(" "+_vm._s(store.store_name)+" ")],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }