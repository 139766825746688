<template>
  <div>
    
      <div id="package_data">
        แพ็จเกจ {{ data_stores.package }} <br/>
        วันหมดอายุ {{ data_stores.expiry_date }}
      </div>
   
    
   
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";

export default {
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatar,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data_stores: "",

      logo: localStorage.getItem("logo"),
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      if (localStorage.getItem("store_id")) {
        const params = {
          store_id: localStorage.getItem("store_id"),
        };
        api
          .post("getstore_id", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("oa response.data :>> ", response.data);
            this.data_stores = response.data.data[0];
          })
          .catch((error) => {});
      }
    },
  },
};
</script>

<style>
#package_data{
  font-size:12px;
}
</style>
  
