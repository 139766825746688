<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      
      <b-avatar
        size="40"
        :src="logo"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
    </template>

 
    </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import api from "@/api";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      logo:"",
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData")),
    // console.log("userData.picture :>> ", this.userData.picture);
    this.logo = this.userData.picture || this.userData.profile;
  },
  methods: {
    //   loaddata() {
    //               console.log('check_connect_oa ON ');
    //   var userData = JSON.parse(localStorage.getItem("userData"));
    //   const params = {
    //     user_main: userData.user_main,
    //   };
    //   var access_token = localStorage.getItem("accessToken");
    //   if (localStorage.getItem("oneSelect") == 1) {
    //   api
    //     .post("check_connect_oa", params, {
    //       headers: {
    //         Authorization: "Bearer " + access_token,
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {

    //       console.log(response.data);
    //       this.items = response.data.data[0];
    //       this.totalRows = response.data.total;
    //       if (response.data.total === 0) {
    //         this.$router.push("/lineoa/add");
    //       }

    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });}
    // },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      // localStorage.clear();
      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("store_name");
      localStorage.removeItem("store_name");
      localStorage.removeItem("store_id");
      localStorage.removeItem("logo");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
